import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateRoute } from './auth';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full',
  },
  {
    path: 'app',
    loadComponent: async () => import('@app/pages/app.component').then((m: any) => m.AppComponent),
    canActivate: [canActivateRoute],
    children: [
      //
      // ***************************************************************************************************************
      // USER
      // ***************************************************************************************************************
      //
      {
        path: 'payments',
        loadComponent: async () => import('@app/pages/user/payments/payments.component').then((m: any) => m.PaymentsComponent),
        canActivateChild: [canActivateRoute],
      },
      {
        path: 'statement',
        loadComponent: async () => import('@app/pages/user/statement/statement.component').then((m: any) => m.StatementComponent),
        canActivateChild: [canActivateRoute],
      },
      {
        path: 'withdraw',
        loadComponent: async () => import('@app/pages/user/withdraw/withdraw.component').then((m: any) => m.WithdrawComponent),
        canActivateChild: [canActivateRoute],
      },
      {
        path: 'account',
        loadComponent: async () => import('@app/pages/user/account/account.component').then((m: any) => m.AccountComponent),
        canActivateChild: [canActivateRoute],
      },
      {
        path: 'recipients',
        loadComponent: async () => import('@app/pages/user/recipients/recipients.component').then((m: any) => m.RecipientsComponent),
        canActivateChild: [canActivateRoute],
      },
      //
      // ***************************************************************************************************************
      // SUPORTE
      // ***************************************************************************************************************
      //
      {
        path: 'suporte/accounts',
        loadComponent: async () => import('@app/pages/suporte/accounts/accounts.component').then((m: any) => m.AccountsComponent),
        canActivateChild: [canActivateRoute],
      },
      {
        path: 'suporte/accounts/:id',
        loadComponent: async () => import('@app/pages/suporte/accounts/account/account.component').then((m: any) => m.AccountComponent),
        canActivateChild: [canActivateRoute],
      },
      {
        path: 'suporte/recipients',
        loadComponent: async () => import('@app/pages/suporte/recipients/recipients.component').then((m: any) => m.RecipientsComponent),
        canActivateChild: [canActivateRoute],
      },
      {
        path: 'suporte/payments',
        loadComponent: async () => import('@app/pages/suporte/payments/payments.component').then((m: any) => m.PaymentsComponent),
        canActivateChild: [canActivateRoute],
      },
      {
        path: 'suporte/statement',
        loadComponent: async () => import('@app/pages/suporte/statement/statement.component').then((m: any) => m.StatementComponent),
        canActivateChild: [canActivateRoute],
      },
      {
        path: 'suporte/withdraw',
        loadComponent: async () => import('@app/pages/suporte/withdraw/withdraw.component').then((m: any) => m.WithdrawComponent),
        canActivateChild: [canActivateRoute],
      },
      {
        path: 'suporte/settings',
        loadComponent: async () => import('@app/pages/suporte/settings/settings.component').then((m: any) => m.SettingsComponent),
        canActivateChild: [canActivateRoute],
      },
      //
      // ***************************************************************************************************************
      // ADMIN
      // ***************************************************************************************************************
      //
      {
        path: 'admin/account',
        loadComponent: async () => import('@app/pages/admin/account/account.component').then((m: any) => m.AccountComponent),
        canActivateChild: [canActivateRoute],
      },
      {
        path: 'admin/recipients',
        loadComponent: async () => import('@app/pages/admin/recipients/recipients.component').then((m: any) => m.RecipientsComponent),
        canActivateChild: [canActivateRoute],
      },
      {
        path: 'admin/statement',
        loadComponent: async () => import('@app/pages/admin/statement/statement.component').then((m: any) => m.StatementComponent),
        canActivateChild: [canActivateRoute],
      },
    ],
  },
  {
    path: 'signin',
    loadComponent: async () => import('@app/auth/signin/signin.component').then((m: any) => m.SigninComponent),
  },
  {
    path: 'signup',
    loadComponent: async () => import('@app/auth/signup/signup.component').then((m: any) => m.SignupComponent),
  },
  {
    path: 'reset',
    loadComponent: async () => import('@app/auth/reset/reset.component').then((m: any) => m.ResetComponent),
  },
  {
    path: 'confirm',
    loadComponent: async () => import('@app/auth/confirm/confirm.component').then((m: any) => m.ConfirmComponent),
  },
  {
    path: 'error',
    loadComponent: async () => import('@app/error/error.component').then((m: any) => m.ErrorComponent),
  },
  {
    path: '**',
    redirectTo: 'error',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class ROUTES {}
