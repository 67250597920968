import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ToastModule, TranslateModule } from '@app/services';

@NgModule({
  imports: [ToastModule.forRoot(), TranslateModule.forRoot()],
})
export class CoreServicesModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreServicesModule) {
    if (parentModule) {
      throw new Error('CoreServicesModule has already been loaded. Import modules in the AppModule only.');
    }
  }
}
