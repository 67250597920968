import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { lastValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConfigInterface } from './config.interface';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private static _config: ConfigInterface = null;

  constructor(private readonly httpClient: HttpClient) {}

  public static get config(): ConfigInterface {
    return ConfigService._config;
  }

  public async init(): Promise<void> {
    const request$ = this.httpClient.get('./config/config.json').pipe(
      catchError((error) => this.handleError(error)),
      switchMap((response) => this.handleResponse(response))
    );
    await lastValueFrom(request$).then((config: ConfigInterface) => {
      ConfigService._config = { ...environment, ...config };
    });
  }

  protected handleError(error: Error): any {
    return throwError(() => error);
  }

  protected handleResponse(response: any): Observable<any> {
    return of(response);
  }
}
