import { NgModule, Optional, SkipSelf } from '@angular/core';
import { reducers } from '@app/store';
import { environment } from '@env/environment';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

@NgModule({
  imports: [
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
})
export class CoreStoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreStoreModule) {
    if (parentModule) {
      throw new Error('CoreSharedModule has already been loaded. Import modules in the AppModule only.');
    }
  }
}
