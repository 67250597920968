import { APP_BASE_HREF, NgOptimizedImage, provideImgixLoader } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ConfigService, configFactory } from '@app/config';
import { CoreServicesModule, CoreSharedModule, CoreStoreModule } from '@app/core';
import { ErrorHandlerUser } from '@app/error';
import { SpinModule } from '@app/services';
import * as Sentry from '@sentry/angular-ivy';
import { AppComponent } from './app.component';
import { appFactory } from './app.factory';
import { ROUTES } from './app.routes';
import { AppService } from './app.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgOptimizedImage,
    SpinModule,
    ROUTES,
    CoreServicesModule,
    CoreSharedModule,
    CoreStoreModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appFactory,
      deps: [AppService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerUser,
    },
    provideImgixLoader('https://cdn.erpclass.com.br'),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
